body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: grayscale;
    overflow-x: auto !important;
}

.ui.top.menu > .right.menu > a > .item {
    height: 100%;
}

#topRightMenu .ui.inverted.menu {
    background-color: rgb(22, 120, 194);
}

#sidebar {
    position: fixed;
    height: 100vh;
    background-color: #f5f5f5;
    padding-top: 68px;
    padding-left: 0;
    padding-right: 0;
    z-index: 100 !important;
}

a {
    outline: none;
}

#sidebar .ui.menu > a {
    outline: none;
}

#sidebar .ui.menu > a > div#dashboardMenu > div.item {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 15px !important;
    padding-bottom: 10px !important;
}

#sidebar .ui.menu > a > div.menuHeader > div.item {
    padding: 10px 5px 5px 5px !important;
    line-height: 20px;
    /*border-radius: 0 !important;*/
    word-break: break-all;
    border-radius: 0px 5px 5px 0px !important;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #d6d6d6;
    font-weight: bold;
    color: rgb(22, 120, 194);
    font-size: 0.6vw;
}

#sidebar .ui.menu > a > div.menuHeader > div.item.active {
    background-color: rgb(22, 120, 194);
    border: none !important;
    color: white;
}

#sidebar .ui.menu  > a > div.menuHeader > div.item.active {
    color: white;
}

#sidebar .ui.menu > a:hover > div.menuHeader > div.item.active {
    color: white;
}

#content {
    padding-top: 56px;
    padding-left: 40px;
    padding-right: 40px;
}

@media (max-width : 500px) {
    #content {
        padding-top: 56px;
        padding-left: 30px;
        padding-right: 30px;
    }
}

#content h1 {
    font-size: 36px;
}

#content .ui.dividing.header {
    width: 100%;
}

.ui.centered.small.circular.image {
    margin-top: 14px;
    margin-bottom: 14px;
}

.ui.borderless.menu {
    box-shadow: none;
    flex-wrap: wrap;
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.ui.mobile.only.grid .ui.menu .ui.vertical.menu {
    display: none;
}

#headerMobile {
    height: 100%;
    vertical-align: middle;
}

#logo {
    padding-bottom: 0;
    padding-top: 0;
}

#manageResultGrid {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
}

/* TODO strange fix for some buttons height (save pointage when adding one for instance) */
.ui.button {
    line-height: initial !important;
}

.divIconLeaflet > div {
    width: 3rem;
    height: 3rem;
    display: block;
    
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    opacity: 0.7;
    border: 1px solid #FFFFFF
}

.leaflet-container {
    z-index: 50;
}

#modalMap.leaflet-container {
    z-index: 0 !important;
}

.hourEndByDayHeader .field {
    display: inline;
}

.customCounterHeader .field {
    display: inline;
}

.settingsHeader {
    background-color: #F9FAFB !important;
}

@media screen and (min-width: 450px) {
    div.loginTabPanes {
        width: 400px;
    }
}

div.loginTabPanes {
    margin: auto;
}

div.loginTabPanes .ui.inverted.blue.menu .active.item {
    background-color: rgb(22, 120, 194) !important;
    /*border: 2px solid white !important;
    border-radius: 5px 5px 0px 0px !important;*/
}

div.loginTabPanes .ui.inverted.blue.menu .active.item i {
    color: #ffffff;
}


div.loginTabPanes .ui.inverted.pointing.menu a.active.item::after {
    background-color: #ffffff !important;
    border: 2px solid rgb(22, 120, 194) !important;
}

/*div.loginTabPanes .ui.inverted.pointing.menu a.active.item:hover {
    color: rgb(22, 120, 194) !important;
}*/

div.loginTabPanes .ui.inverted.blue.menu {
    margin-bottom: 0 !important;
    border: 2px solid white !important;
    border-bottom: none !important;
    border-radius: 5px 5px 0px 0px !important;
}

div.loginTabPanes .ui.inverted.blue.menu .item {
    background-color: #d6d6d6 !important;
    border-radius: 0 0 0 0 !important;
}

div.loginTabPanes .ui.inverted.blue.menu .item i {
    color: rgb(22, 120, 194);
}

div.loginTabPanes .ui.segment.active.tab {
    margin-top: 0 !important;
    border-radius: 0 0 5px 5px !important;
}


#exclamationCircle {
    font-size: 0.6vw;
}

#exclamationCircle::before {
    margin-left: 0.3vw !important;
}

.firstRow {
    padding-bottom: 0px !important;
}

#manualCounterHeaderText, #manualSecondCounterHeaderText, #manualThirdCounterHeaderText, #nightShiftHeaderText {
    align-self: auto !important;
}

.ui.slider.checkbox.settingsBlackChk label {
    color: rgba(0,0,0,.87) !important;
}

/*#employeesTbl, #profilesTbl, #teamsTbl, */#dashboardTbl {
    position: relative;
}

/*#employeesTbl th, #profilesTbl th, #teamsTbl th, */#dashboardTbl th {
    position: sticky;
    top: 0;
    background: #f9fafb;
    z-index: 10;
}

/*#employeesTbl th:hover, #profilesTbl th:hover, #teamsTbl th:hover, */#dashboardTbl th:hover {
    z-index: 100 !important;
}

#employeesTbl:hover, #profilesTbl:hover, #teamsTbl:hover, #rpiHardwareTbl:hover, #phoneHardwareTbl:hover, #gpsHardwareTbl:hover {
    cursor: pointer;
}

.displaySettingsGrid {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

@media screen and (min-width: 450px) {
    .displaySettingsGrid, .dashboardGrid {
        width: 100% !important;
    }
    .displaySettingsFirstCol {
        padding-left: 0px !important;
    }
    .displaySettingsSecondCol {
        padding-right: 0px !important;
    }

    [data-tooltip]::after {
        /*z-index: 100 !important;*/
        white-space: pre !important;
    }
}

@media screen and (max-width: 449px) {
    .displaySettingsGrid > .column {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    [data-tooltip]::after {
        visibility: hidden !important;
    }
}

.displaySettingsGrid .ui.dividing.header, .dashboardGrid .ui.dividing.header {
    width: auto !important;
}

.checkboxInTable {
    z-index: 0 !important;
}

#accordionOptInManage {
    user-select: none;
}

#accordionDepInMovingRow:hover, #accordionOptInManage:hover {
    cursor: pointer !important;
    text-decoration: underline;
}

#importEmployeesModal {
    left: auto !important;
}

#pointagesTbl table #firstHeader th {
    z-index: 1 !important;
    top: 55px;
    position: sticky;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: none;
    background-color: #f5f5fb;
}

#pointagesTbl table #secondHeader th {
    z-index: 1 !important;
    top: 90px;
    position: sticky;
    border-bottom: .2em solid #589acd;
    background-color: #f5f5fb;
}

#pointagesSettingsBtn {
    position: fixed;
    bottom: 15px;
    right: 15px;
    opacity: 0.8;
    z-index: 1100;
}